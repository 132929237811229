<template>
  <div id="form-crud-orientation">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    <div class="card border-0 shadow p-3">
      <form @submit.prevent="initSubmit">

        <div class="form-group">
          <label for="number">{{ $t('common.number') }} *</label>
          <input class="form-control" id="number" v-model="orientation.number" type="text">
          <div v-if="errorsForm.number">
            <p class="form-error text-danger" v-for="(error, index) in errorsForm.number.errors" :key="index">{{ error }}</p>
          </div>
        </div>

        <input type="submit" class="btn mt-4 mb-2 btn-success" :value="valueButtonSubmit" />
      </form>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  props: {
    orientation: Object,
    id: Number,
    actionForm: String
  },
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      errorsForm: [],
    }
  },
  created: function() {
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- SUBMIT ------------------*/
    initSubmit()
    {
      // If orientation (create or update)
      if(this.actionForm === 'edit')
      {
        this.edit()
      }
      else {
        this.create()
      }
    },
    /**
     * Create orientation (submit form)
     */
    create() {

      this.showLoader()

      this.$store.dispatch('createOrientation', this.orientation)
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Update orientation (submit form)
     */
    edit() {
      this.showLoader()

      this.$store.dispatch('updateOrientation', { id: this.orientation.id, orientation: this.orientation })
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Redirect to orientation-list after submit
     * Refresh ErrorsForm
     */
    redirectAndRefresh()
    {
      // Redirect
      this.$router.push({ name: 'orientation-index' })

      // Refresh errors form
      this.errorsForm = []
    }
  },
  computed: {
    /**
     * Display value for button submit
     * @returns {string}
     */
    valueButtonSubmit() {
      let value = ''
      if(this.actionForm === 'edit')
      {
        value = this.$t('pages.orientation.editOrientation')
      }
      else {
        value = this.$t('pages.orientation.createOrientation')
      }

      return value
    },
    ...mapState(['OrientationModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay')
  }
}
</script>
